import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { TypeAnimation } from "react-type-animation";
import "../hero/style.css";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <div>
        <div>
          <div className="relative pt-48 pb-12 bg-black xl:pt-60 sm:pb-16 lg:pb-32 xl:pb-48 2xl:pb-56">
            <div className="absolute inset-0 overflow-hidden">
              <img
                className="object-cover w-full h-full animate-scale"
                src="https://ecosourcing.org/dev/admin/uploads/page/about-us/1645731499scPob.jpg"
                alt=""
              />
            </div>

            <div className="relative">
              <div className="px-6 mx-auto sm:px-8 lg:px-12 max-w-7xl">
                <div className="w-full lg:w-2/3 xl:w-1/2">
                  <h1 className="font-sans text-base font-normal tracking-tight text-white text-opacity-70">
                    GALLERY
                  </h1>
                  <p className="mt-6 tracking-tighter text-[#80C42B]">
                    {/* <span className="font-sans font-normal text-7xl"> */}
                    <TypeAnimation
                      sequence={[
                        "HAVE A SNEAK", // Types 'One'
                        1000, // Waits 1s
                        "PEAK OF", // Deletes 'One' and types 'Two'
                        2000, // Waits 2s
                        "OUR WORK", // Types 'Three' without deleting 'Two'
                        () => {},
                      ]}
                      wrapper="span"
                      cursor={true}
                      repeat={Infinity}
                      style={{ fontSize: "3.5rem", display: "inline-block" }}
                    />
                    {/* </span> */}
                    <br />
                    <span className="font-serif italic font-normal text-8xl"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="py-12 bg-gray-900 sm:py-16 lg:py-20">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="text-center">
            <h2 class="text-3xl font-bold text-white sm:text-4xl xl:text-5xl font-pj">
              Join millions getting more resources
            </h2>
          </div>

          <div class="mt-8 sm:mt-12">
            <svg
              class="w-auto h-4 mx-auto text-gray-600"
              viewBox="0 0 172 16"
              fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 11 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 46 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 81 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 116 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 151 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 18 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 53 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 88 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 123 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 158 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 25 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 60 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 95 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 130 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 165 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 32 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 67 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 102 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 137 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 172 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 39 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 74 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 109 1)"
              />
              <line
                y1="-0.5"
                x2="18.0278"
                y2="-0.5"
                transform="matrix(-0.5547 0.83205 0.83205 0.5547 144 1)"
              />
            </svg>
          </div>

          <div class="grid grid-cols-1 mt-16 text-center sm:text-left gap-y-12 gap-x-8 sm:grid-cols-3 lg:gap-0">
            <div class="lg:px-14">
              <p class="text-5xl font-bold text-white font-pj">1.5M</p>
              <p class="mt-5 text-lg font-normal text-gray-300">
                Lorem ipsum dolor sit amet, consect etur adipis elit. Sit enim
                nec.
              </p>
            </div>

            <div class="relative lg:px-14">
              <div class="absolute bottom-0 left-0 hidden w-px h-16 bg-gray-600 lg:block"></div>
              <p class="text-5xl font-bold text-white font-pj">41%</p>
              <p class="mt-5 text-lg font-normal text-gray-300">
                Lorem ipsum dolor sit amet, consect etur adipis elit. Sit enim
                nec.
              </p>
            </div>

            <div class="relative lg:px-14">
              <div class="absolute bottom-0 left-0 hidden w-px h-16 bg-gray-600 lg:block"></div>
              <p class="text-5xl font-bold text-white font-pj">3964+</p>
              <p class="mt-5 text-lg font-normal text-gray-300">
                Lorem ipsum dolor sit amet, consect etur adipis elit. Sit enim
                nec.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 bg-white sm:py-16 lg:py-4">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto xl:max-w-4xl">
            <div className="mt-10">
              <h2 className="mt-12 text-3xl font-bold text-gray-900 font-pj">
                Exploring Investment Opportunities
              </h2>
              <p className="mt-6 text-base font-normal leading-7 text-gray-700 font-pj">
                In our pursuit of excellence and innovation, Yaf Trading Co. Ltd
                is actively seeking opportunities to invest in the right
                sectors, further diversifying our portfolio and expanding our
                footprint in Thailand and beyond. With a proven track record of
                success, a commitment to quality, and a passion for growth, we
                are poised to seize new opportunities and create value for our
                partners, investors, and the communities we serve.
              </p>

              <div className="relative mt-16">
                <div className="absolute -inset-2">
                  <div
                    className="w-full h-full mx-auto opacity-30 blur-lg filter"
                    // style="background: linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)"
                  ></div>
                </div>

                <img
                  className="relative rounded-xl"
                  src="gallery5.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-normal text-black sm:text-4xl lg:text-5xl xl:text-6xl">
              Lets see our goals
            </h2>
          </div>

          <div class="max-w-4xl mx-auto mt-12 space-y-12 md:space-y-20 sm:mt-16 lg:mt-20">
            <div class="grid items-center grid-cols-1 md:grid-cols-2 md:gap-x-16 gap-y-8">
              <div>
                <img class="w-full rounded-md" src="about-us1.png" alt="" />
              </div>
              <div>
                <h3 class="mt-6 text-3xl font-normal text-black lg:mt-12">
                  OUR VISION
                </h3>
                <p class="mt-3 text-base font-normal text-black">
                  Unparalleled Quality: Elevating standards with garments
                  crafted to perfection.
                </p>
                <p class="mt-3 text-base font-normal text-black">
                  Reliability: Consistent delivery and service excellence, every
                  time.
                </p>
                <p class="mt-3 text-base font-normal text-black">
                  Sustainability: Leading the charge in eco-friendly garment
                  production.
                </p>
                <p class="mt-3 text-base font-normal text-black">
                  Innovation: Pioneering new trends and techniques in the
                  fashion industry.
                </p>
                <p class="mt-3 text-base font-normal text-black">
                  Partnership: Your trusted ally in achieving fashion goals with
                  integrity.
                </p>
              </div>
            </div>

            <div class="grid items-center grid-cols-1 md:grid-cols-2 md:gap-x-16 gap-y-8">
              <div class="md:order-last">
                <img class="w-full rounded-md" src="about-us2.png" alt="" />
              </div>

              <div>
                <h3 class="mt-6 text-3xl font-normal text-black lg:mt-12">
                  OUR MISSION
                </h3>
                <p class="mt-3 text-base font-normal text-black">
                  Guiding Excellence: Supporting clients from concept to
                  delivery with specialized departments.
                </p>
                <p class="mt-3 text-base font-normal text-black">
                  Sustainability Commitment: Partnering with eco-conscious
                  factories for ethical production.
                </p>
                <p class="mt-3 text-base font-normal text-black">
                  Innovation Drive: Embracing technology and best practices for
                  efficient supply chain management
                </p>
                <p class="mt-3 text-base font-normal text-black">
                  Trust-Based Partnerships: Building enduring relationships on
                  integrity and shared goals.
                </p>
                <p class="mt-3 text-base font-normal text-black">
                  Impactful Journey: Redefining garment sourcing through
                  sustainable practices and partnerships.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12  bg-white sm:py-16 lg:py-28">
        <div className="">
          <div className="relative overflow-hidden pl-[10%]">
            <div className="absolute inset-0">
              <img
                className="object-cover w-full h-full"
                src="about-us3.png"
                alt=""
              />
            </div>
            <div className="absolute inset-0 backdrop-blur-[1px] bg-gradient-to-r from-gray-900 via-gray-900/50 lg:via-gray-900/20 to-transparent"></div>

            <div className="relative p-8  sm:py-12 sm:px-16 sm:max-w-3xl">
              <p className="mt-12 text-2xl font-bold text-white sm:text-3xl">
                <h2 className="text-3xl uppercase font-bold leading-tight text-[#80C42B] sm:text-4xl lg:text-5xl lg:leading-tight">
                  Sustainable Garment Procurement Made Easy
                </h2>
              </p>

              <ul>
                <li className="text-white mt-2">
                  Eco-conscious Sourcing: Connecting buyers with sustainable
                  factories, ensuring ethical production practices.
                </li>
                <li className="text-white mt-2">
                  Creative Design Support: Assisting clients in creating
                  standout garments with expert design collaboration.
                </li>
                <li className="text-white mt-2">
                  Quality Assurance & Efficient Procurement: Meticulous
                  oversight from factory to delivery, supported by streamlined
                  procurement assistance and supply management.
                </li>
              </ul>
              <div className="mt-12">
                <a
                  href="#"
                  title=""
                  className="inline-flex items-center justify-center px-6 py-3 text-base font-bold !text-white transition-all duration-200 bg-[#80C42B] border border-transparent rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                  role="button"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AboutUs;
