import React from "react";
import { TypeAnimation } from "react-type-animation";
import "./style.css";
const Hero = () => {
  return (
    <div className="relative ">
      <section className="relative pb-12 bg-white pt-28 sm:pb-16 sm:pt-32 lg:pb-20 xl:pt-48 xl:pb-32">
        <div className="absolute inset-0 overflow-hidden">
          <img
            className="animate-scale hidden  ml-auto object-cover w-[100%] h-[100%] md:block animate-pulse"
            src="/gallery5.jpg"
            alt=""
          />
          {/* <div className="relative group ">
            <div className="w-[273px] h-[273px] rounded-lg cursor right-[30%] bg-[#80C42B] absolute bottom-10  ">
              <img
                width={45}
                height={45}
                className="ml-auto mt-5 mr-5"
                src="/icon1.png"
              />
              <div className="relative">
                <p className="text-white absolute mt-[100px] ml-5 uppercase font-medium text-xl">
                  Our Products
                </p>
              </div>
              <div class="opacity-0 w-[273px] rounded-lg h-[0px] transition-all duration-300 group-hover:opacity-100 group-hover:h-[273px] duration-300 absolute inset-x-0 bottom-0 flex justify-center items-end text-xl bg-[#000] text-black font-semibold">
                <p className="text-white absolute bottom-20 left-5 uppercase font-medium text-xl">
                  Our Products
                </p>
              </div>
            </div>
          </div> */}
        </div>

        <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="flex">
            <div className="w-full md:max-w-xs lg:max-w-xl">
              <p className="text-[#fff] font-bold text-xl">Hola Garments</p>
              <div className="min-h-[110px]  text-white">
                <p className="lg:text-white sm:text-black text-black">
                  <TypeAnimation
                    className="text-2xl !text-[#80C42B] font-bold sm:text-5xl"
                    sequence={[
                      `WHERE FASHION\nMEETS SUSTAINABILITY`, // actual line-break inside string literal also gets animated in new line, but ensure there are no leading spaces
                      1000,
                      "",
                    ]}
                    repeat={Infinity}
                    style={{
                      whiteSpace: "pre-line",
                      // color: "!white",
                    }}
                  />
                </p>
              </div>

              <p className="mt-5 text-medium font-normal leading-7 lg:text-white sm:text-black text-black">
                From cost savings to increased collaboration opportunities,
                coworking spaces can make for ideal offices, especially for
                small and growing businesses.
              </p>

              <div className="relative inline-flex mt-10 group">
                <div className="absolute transitiona-all duration-1000 opacity-70 inset-0 rounded-xl blur-lg filter group-hover:opacity-100 group-hover:duration-200"></div>

                <a
                  href="#"
                  title=""
                  className="relative inline-flex items-center justify-center px-6 py-3 text-base font-bold text-white transition-all duration-200 bg-[#80C42B] border border-transparent rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  role="button"
                >
                  Discover More
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 md:hidden sm:mt-16">
          <img
            className="object-cover w-full h-full"
            src="/banner.png"
            alt=""
          />
        </div>
      </section>
    </div>
  );
};

export default Hero;
