import React from "react";

const BestProduct = () => {
  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20">
      <div className="px-4 mx-auto flex justify-center items-center  sm:px-6 lg:px-8 max-w-7xl">
        <div className="mx-auto text-center lg:text-center sm:max-w-lg lg:mx-0">
          <h2 className="text-3xl  font-bold  leading-tight text-black sm:text-4xl lg:text-5xl lg:leading-tight">
            Best selling products
          </h2>

          <p className="mt-4 text-base font-normal leading-7 text-gray-600">
            Choose the most suitable products category for you.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 px-4 mt-12 text-center xl:gap-3 sm:gap-x-4 sm:mt-16 md:gap-y-10 sm:grid-cols-2 md:grid-cols-3 gap-y-8 lg:grid-cols-5 xl:px-3">
        <div className="relative group">
          <div className="relative">
            <div className="overflow-hidden aspect-w-3 aspect-h-4">
              <img
                className="object-cover w-full max-h-[400px] transition-all duration-300 origin-bottom group-hover:scale-110"
                src="gallery10.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="relative group">
          <div className="relative">
            <div className="overflow-hidden aspect-w-3 aspect-h-4">
              <img
                className="object-cover w-full h-full transition-all max-h-[400px] duration-300 origin-bottom group-hover:scale-110"
                src="gallery11.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="relative group">
          <div className="relative">
            <div className="overflow-hidden aspect-w-3 aspect-h-4">
              <img
                className="object-cover w-full h-full transition-all max-h-[400px] duration-300 origin-bottom group-hover:scale-110"
                src="gallery3.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="relative group">
          <div className="relative">
            <div className="overflow-hidden aspect-w-3 aspect-h-4">
              <img
                className="object-cover w-full h-full transition-all max-h-[400px] duration-300 origin-bottom group-hover:scale-110"
                src="gallery6.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="relative group">
          <div className="relative">
            <div className="overflow-hidden aspect-w-3 aspect-h-4">
              <img
                className="object-cover w-full h-full transition-all max-h-[400px] duration-300 origin-bottom group-hover:scale-110"
                src="gallery7.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestProduct;
