import React from "react";

const Category = () => {
  return (
    <div>
      <section className="py-12 sm:py-16 lg:py-20 bg-gray-50">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-8xl">
          <div className="text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl lg:leading-tight">
              Featured Categories
            </h2>

            <p className="text-gray-500 font-normal text-base mt-2.5">
              Choose from wide variety of items
            </p>
          </div>

          <div className="grid grid-cols-1 gap-5 mt-8 text-center sm:mt-12 sm:grid-cols-2 lg:grid-cols-4 sm:gap-6">
            <div className="relative overflow-hidden !h-[500px] rounded-lg group">
              <div className="absolute inset-0 ">
                <img
                  className="object-cover w-full h-full"
                  src="/men.png"
                  alt=""
                />
              </div>

              <div className="relative aspect-w-4 aspect-h-3">
                <div className="flex items-end justify-center p-6">
                  <span className="inline-flex items-center justify-center px-3 py-1.5 text-sm font-bold text-gray-900 bg-white rounded">
                    {" "}
                    Men{" "}
                  </span>
                </div>
              </div>

              <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 bg-[#80C42B] opacity-0 group-hover:opacity-100">
                <div className="flex flex-col items-center">
                  <svg
                    className="w-12 h-12 mx-auto text-white"
                    viewBox="0 0 48 48"
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M44.2 33.2727H27.7545L14.9636 29.6182V33.2727H4V15H5.82727C5.82727 15 9.75591 16.8273 13.1364 16.8273C16.5168 16.8273 20.4455 15 20.4455 15H22.2727L35.0636 24.1364L41.9159 26.8773C43.2864 27.4255 44.2 28.7959 44.2 30.2577V33.2727Z"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="mt-8 text-lg font-bold text-white">
                    <a href="#" title="">
                      Men
                      <span
                        className="absolute inset-0"
                        aria-hidden="true"
                      ></span>
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="relative overflow-hidden !h-[500px] rounded-lg group">
              <div className="absolute inset-0">
                <img
                  className="object-cover w-full h-full"
                  src="/women.png"
                  alt=""
                />
              </div>

              <div className="relative aspect-w-4 aspect-h-3">
                <div className="flex items-end justify-center p-6">
                  <span className="inline-flex items-center justify-center px-3 py-1.5 text-sm font-bold text-gray-900 bg-white rounded">
                    {" "}
                    Women{" "}
                  </span>
                </div>
              </div>

              <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 bg-[#80C42B] opacity-0 group-hover:opacity-100">
                <div className="flex flex-col items-center">
                  <svg
                    className="w-12 h-12 mx-auto text-white"
                    viewBox="0 0 48 48"
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M44.2 33.2727H27.7545L14.9636 29.6182V33.2727H4V15H5.82727C5.82727 15 9.75591 16.8273 13.1364 16.8273C16.5168 16.8273 20.4455 15 20.4455 15H22.2727L35.0636 24.1364L41.9159 26.8773C43.2864 27.4255 44.2 28.7959 44.2 30.2577V33.2727Z"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="mt-8 text-lg font-bold text-white">
                    <a href="#" title="">
                      Women
                      <span
                        className="absolute inset-0"
                        aria-hidden="true"
                      ></span>
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="relative overflow-hidden !h-[500px] rounded-lg group">
              <div className="absolute inset-0">
                <img
                  className="object-cover w-full h-full"
                  src="/boy.png"
                  alt=""
                />
              </div>

              <div className="relative aspect-w-4 aspect-h-3">
                <div className="flex items-end justify-center p-6">
                  <span className="inline-flex items-center justify-center px-3 py-1.5 text-sm font-bold text-gray-900 bg-white rounded">
                    {" "}
                    Boy{" "}
                  </span>
                </div>
              </div>

              <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 bg-[#80C42B] opacity-0 group-hover:opacity-100">
                <div className="flex flex-col items-center">
                  <svg
                    className="w-12 h-12 mx-auto text-white"
                    viewBox="0 0 48 48"
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M44.2 33.2727H27.7545L14.9636 29.6182V33.2727H4V15H5.82727C5.82727 15 9.75591 16.8273 13.1364 16.8273C16.5168 16.8273 20.4455 15 20.4455 15H22.2727L35.0636 24.1364L41.9159 26.8773C43.2864 27.4255 44.2 28.7959 44.2 30.2577V33.2727Z"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="mt-8 text-lg font-bold text-white">
                    <a href="#" title="">
                      Boy
                      <span
                        className="absolute inset-0"
                        aria-hidden="true"
                      ></span>
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="relative overflow-hidden !h-[500px] rounded-lg group">
              <div className="absolute inset-0">
                <img
                  className="object-cover w-full h-full"
                  src="/girl.png"
                  alt=""
                />
              </div>

              <div className="relative aspect-w-4 aspect-h-3">
                <div className="flex items-end justify-center p-6">
                  <span className="inline-flex items-center justify-center px-3 py-1.5 text-sm font-bold text-gray-900 bg-white rounded">
                    {" "}
                    Girl{" "}
                  </span>
                </div>
              </div>

              <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 bg-[#80C42B] opacity-0 group-hover:opacity-100">
                <div className="flex flex-col items-center">
                  <svg
                    className="w-12 h-12 mx-auto text-white"
                    viewBox="0 0 48 48"
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M44.2 33.2727H27.7545L14.9636 29.6182V33.2727H4V15H5.82727C5.82727 15 9.75591 16.8273 13.1364 16.8273C16.5168 16.8273 20.4455 15 20.4455 15H22.2727L35.0636 24.1364L41.9159 26.8773C43.2864 27.4255 44.2 28.7959 44.2 30.2577V33.2727Z"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="mt-8 text-lg font-bold text-white">
                    <a href="#" title="">
                      Girl
                      <span
                        className="absolute inset-0"
                        aria-hidden="true"
                      ></span>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Category;
