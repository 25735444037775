import React from "react";
import Header from "../header/Header";
import Hero from "../hero/Hero";
import Feature from "../feature/Feature";
import Stats from "../stats/Stats";
import Step from "../steps/Step";
import Book from "../book/Book";
import Faq from "../faq/Faq";
import Footer from "../footer/Footer";
import LogoCloud from "../logoCloud/LogoCloud";
import BestProduct from "../best-product/BestProduct";
import BlogFeature from "../blog-feature/BlogFeature";
import Testimonial from "../testimonial/Testimonial";
import Success from "../success/Success";
import Category from "../category/Category";
import Why from "../why/Why";

const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Feature />
      <Success />
      <Category />
      <BlogFeature />
      {/* <Why /> */}
      <BestProduct />
      <Footer />
    </div>
  );
};

export default Home;
