import React, { useCallback, useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

const galleryFilter = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 3,
    name: "Dining",
  },
  {
    id: 4,
    name: "Meeting",
  },
  {
    id: 4,
    name: "Wellness",
  },
];
const galleryList = [
  {
    id: 1,
    img: "gallery3.jpg",
  },

  {
    id: 1,
    img: "gallery6.jpg",
  },
  {
    id: 1,
    img: "gallery7.jpg",
  },
  {
    id: 1,
    img: "gallery10.jpg",
  },
  {
    id: 1,
    img: "gallery11.jpg",
  },
];
function GallaryImages({ data }) {
  const [isOpen, setOpen] = useState(false);
  const thumbnailsRef = React.useRef(null);

  const [isOpenimg, setOpenimg] = useState({
    openingState: false,
    openingIndex: 0,
  });
  const [activeIndex, setActiveIndex] = useState("All");
  const handleActiveIndex = useCallback(
    (name) => {
      setActiveIndex(name);
    },
    [activeIndex]
  );

  const modifiedGalleryInfo = galleryList?.map((item) => ({ src: item.img }));
  return (
    <div>
      {/* <div className="flex my-[40px] justify-center items-center flex-row">
        {galleryFilter?.map((item, index) => {
          return (
            <div
              className={`h-[40px]  cursor-pointer border border-[#d7dee5] flex items-center justify-center rounded w-[180px] ${
                activeIndex === item.name ? "bg-[#003768]" : ""
              }`}
              onClick={() => handleActiveIndex(item.name)}
              key={index}
            >
              <p
                className={`font-medium 3xl:text-[16px] 2xl:text-[16px] xl:text-[16px] lg:text-[15px] md:text-[15px] sm:text-[14px] text-[13px] ${
                  activeIndex === item.name ? "text-[#fff]" : "text-[#003768]"
                }`}
              >
                {item?.name}
              </p>
            </div>
          );
        })}
      </div> */}
      <div className="home-one-gallery two pt-120 mb-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title1 text-center"></div>
            </div>
          </div>
          <div className="px-10 mt-[140px] sm:px-6 lg:px-10 ">
            <div className="grid  grid-cols-2 gap-6 mt-8 sm:mt-12 sm:gap-6 lg:grid-cols-4">
              {galleryList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="relative flex flex-col w-full !min-h-[330px] overflow-hidden bg-white rounded group"
                  >
                    <div
                      onClick={() =>
                        setOpenimg({ openingState: true, openingIndex: index })
                      }
                      className="flex-shrink-0  overflow-hidden "
                    >
                      <img
                        className="object-cover w-full !min-h-[330px] transition-all duration-300 group-hover:scale-125"
                        src={item.img}
                        alt=""
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Lightbox
        // className="img-fluid"
        open={isOpenimg.openingState}
        plugins={[Fullscreen, Thumbnails]}
        index={isOpenimg.openingIndex}
        close={() => setOpenimg(false)}
        styles={{ container: { backgroundColor: "rgba(0, 0, 0, .9)" } }}
        slides={modifiedGalleryInfo}
        carousel={true}
        thumbnails={{ ref: thumbnailsRef }}
        on={{
          click: () => {
            (thumbnailsRef.current?.visible
              ? thumbnailsRef.current?.hide
              : thumbnailsRef.current?.show)?.();
          },
        }}
      />
    </div>
  );
}

export default GallaryImages;
