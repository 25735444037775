import React, { useState } from "react";
import { TypeAnimation } from "react-type-animation";
import "../../hero/style.css";
import Model from "../products/Model";
const serviceList = [
  {
    id: 1,
    name: "Sourcing",
    imgUrl: "service4.png",
    description:
      "As a buying house specializing in the garments industry, our primary goal is to connect buyers with sustainable products. We prioritize sustainability throughout our sourcing process, partnering exclusively with sustainable factories. Our services encompass quality assurance, design support, and timely shipment management. With a focus on vetted suppliers, clear communication, and ongoing improvement, we're the trusted partner for eco-conscious sourcing solutions.",
  },
  {
    id: 2,
    name: "Embracing Sustainability",
    imgUrl: "service1.png",
    description:
      "At our buying house, we exclusively refer our buyers' production to sustainable factories committed to environmental stewardship. These factories exemplify sustainability through various initiatives such as being certified green factories, implementing solar power, and employing wastewater treatment plants (WTP), effluent treatment plants (ETP), energy-efficient boilers (EGB), and more. By partnering solely with such factories, we ensure that our clients' production processes align with the highest standards of environmental responsibility.",
  },
  {
    id: 3,
    name: "Creative Studio",
    imgUrl: "service6.png",
    description:
      "Our buying house boasts a dedicated design support department equipped to assist buyers in crafting designs for the production of a wide range of ready-made garments (RMG) products, including knitwear, woven garments, denim, and sweaters. Whether clients seek assistance in conceptualizing new designs or refining existing ones, our team collaborates closely to bring their vision to life. With expertise across various garment categories, we ensure that designs meet both aesthetic appeal and production feasibility, empowering clients to create standout products in the market. ",
  },
  {
    id: 4,
    name: "Quality Assurance",
    imgUrl: "service.png",
    description:
      "At our buying house, quality assurance isn't just a promise; it's our commitment from the factory floor to your doorstep. We meticulously oversee every step of production, ensuring that our buyers receive products of impeccable quality. From rigorous factory inspections to meticulous product testing, our dedicated team leaves no stone unturned in upholding the highest standards of quality. With our unwavering focus on excellence, you can trust us to deliver nothing but the best.",
  },
  {
    id: 5,
    name: "Procurement Assistance Unit",
    imgUrl: "service3.png",
    description:
      "Our Buying Team Support Department serves as the backbone of our operations, dedicated to empowering our buying teams with the resources and assistance they need to excel. From supplier research and negotiation to order processing and logistics coordination, our team ensures seamless operations at every stage of the sourcing process. With a focus on efficiency, accuracy, and customer satisfaction, we're committed to providing unparalleled support that enables our buying teams to thrive in a dynamic and competitive market.",
  },
  {
    id: 6,
    name: "Supply Management",
    imgUrl: "service2.png",
    description:
      "Supply management is the cornerstone of our operations, encompassing the strategic coordination and optimization of all aspects of the supply chain. From supplier selection and relationship management to inventory control and logistics, our approach to supply management is characterized by efficiency, transparency, and innovation. By leveraging advanced analytics, technology, and best practices, we ensure that our supply chain remains agile, resilient, and responsive to the dynamic needs of our customers and the market. With a relentless focus on cost-effectiveness, quality, and sustainability, we strive for excellence in every facet of supply management to drive value and competitive advantage for our organization.",
  },
];
const ServiceList = () => {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleOpen = (data) => {
    setOpen(data);
  };
  const handleIndex = (index, open) => {
    setCurrentIndex(index);
    setOpen(open);
  };

  return (
    <div>
      <div>
        <div className="relative pt-48 pb-12 bg-black xl:pt-60 sm:pb-16 lg:pb-32 xl:pb-48 2xl:pb-56">
          <div className="absolute inset-0  overflow-hidden">
            <img
              className="object-cover w-full h-full animate-scale"
              src="service_banner.png"
              alt=""
            />
          </div>

          <div className="relative">
            <div className="px-6 mx-auto sm:px-8 lg:px-12 max-w-7xl">
              <div className="w-full lg:w-2/3 xl:w-1/2">
                <h1 className="font-sans text-base font-normal tracking-tight text-white text-opacity-70">
                  SERVICES
                </h1>
                <p className="mt-6 tracking-tighter w-full text-[#80C42B]">
                  {/* <span className="font-sans font-normal text-7xl"> */}
                  <TypeAnimation
                    sequence={[
                      "PROFESSIONALLY", // Types 'One'
                      1000, // Waits 1s
                      "MANAGED APPARELS", // Deletes 'One' and types 'Two'
                      1000, // Waits 2s
                      "SOURCING", // Types 'Three' without deleting 'Two'
                      () => {},
                    ]}
                    wrapper="span"
                    cursor={true}
                    repeat={Infinity}
                    style={{ fontSize: "3.5rem" }}
                  />
                  {/* </span> */}
                  <br />
                  <span className="font-serif italic font-normal text-8xl"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center mt-[140px]  flex-wrap justify-center ">
        {serviceList.map((item, index) => {
          return (
            <section
              onClick={() => handleIndex(index, true)}
              key={index}
              className="py-8 bg-white sm:py-10 lg:py-14 xl:py-16"
            >
              <div
                className={`px-4 mx-auto sm:px-6 ${
                  index % 2 == 0 ? "mt-[-120px]" : ""
                } lg:px-8 max-w-4xl`}
              >
                <div className="relative">
                  <div className="overflow-hidden aspect-square sm:aspect-video  group">
                    <img
                      className={`object-cover  w-full h-full transition-all duration-200 group-hover:scale-110`}
                      src={item.imgUrl}
                      alt=""
                    />
                  </div>
                  <div className="absolute inset-0 pointer-events-none bg-gradient-to-t from-gray-900 to-transparent rounded-lg lg:rounded-xl opacity-80"></div>

                  <div className="absolute bottom-0 w-full px-4 py-8 text-center -translate-x-1/2 left-1/2 lg:p-12">
                    <p className="text-lg font-semibold text-white sm:text-xl lg:text-3xl">
                      {item.name}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          );
        })}
      </div>
      {open && (
        <Model setOpen={handleOpen}>
          <section className="py-10 bg-gray-900 sm:py-16 lg:py-20">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
              <div className="relative bg-white sm:p-10 xl:p-14">
                <div className="grid grid-cols-1 lg:grid-cols-12 gap-y-8 lg:gap-x-10 xl:gap-x-20">
                  <div className="relative lg:col-span-5">
                    <img
                      className="object-cover w-full h-full sm:rounded-md"
                      src={serviceList[currentIndex].imgUrl}
                      alt=""
                    />
                  </div>

                  <div className="px-4 pb-8 lg:col-span-7 xl:pr-16">
                    <nav className="flex">
                      <ol role="list" className="flex items-center space-x-1">
                        <li>
                          <div className="-m-1">
                            <a
                              href="#"
                              className="p-1 text-sm font-medium text-gray-600 rounded-md focus:outline-none focus:ring-2 focus:text-gray-900 focus:ring-gray-900 hover:text-gray-700"
                            >
                              {" "}
                              Home{" "}
                            </a>
                          </div>
                        </li>

                        <li>
                          <div className="flex items-center">
                            <span className="flex-shrink-0 text-gray-300">
                              {" "}
                              -{" "}
                            </span>
                            <div className="-m-1">
                              <a
                                href="#"
                                className="p-1 ml-1 text-sm font-medium text-gray-600 rounded-md focus:outline-none focus:ring-2 focus:text-gray-900 focus:ring-gray-900 hover:text-gray-700"
                              >
                                {" "}
                                Services{" "}
                              </a>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="flex items-center">
                            <span className="flex-shrink-0 text-gray-300">
                              {" "}
                              -{" "}
                            </span>
                            <div className="-m-1">
                              <a
                                href="#"
                                className="p-1 ml-1 text-sm font-medium text-gray-400 rounded-md focus:outline-none focus:ring-2 focus:text-gray-900 focus:ring-gray-900 hover:text-gray-700"
                                aria-current="page"
                              >
                                {" "}
                                {serviceList[currentIndex].name}
                              </a>
                            </div>
                          </div>
                        </li>
                      </ol>
                    </nav>

                    <ul className="mt-4 space-y-3 text-base font-medium text-black list-disc list-inside">
                      {serviceList[currentIndex].description}
                    </ul>
                  </div>
                </div>

                <div
                  onClick={() => setOpen(false)}
                  className="absolute top-3 right-3 lg:top-4 lg:right-4"
                >
                  <button
                    type="button"
                    className="p-1 -m-1 text-white transition-all duration-200 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 lg:text-gray-400 hover:text-gray-900 hover:bg-gray-100"
                  >
                    <svg
                      className="w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </Model>
      )}
    </div>
  );
};

export default ServiceList;
