import React, { useCallback, useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import Model from "./Model";
import "../../hero/style.css";

const productList = [
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men1.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men2.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men3.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },

  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men4.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men5.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men6.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men7.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men8.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men9.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men10.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men11.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men12.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men13.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men14.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men15.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men16.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men17.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men18.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men19.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men20.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },

  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men21.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men22.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men23.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
  {
    id: 6,
    type: "Men",
    name: "MANS014",
    imgUrl: "/men24.png",
    weight: "Weight- 10.5 oz.",
    material: "98% Cotton, 2% Elastane",
    size: "Regular Fit",
    price: 200,
  },
];
const galleryFilter = [
  {
    id: 1,
    name: "Men",
  },
  {
    id: 3,
    name: "Women",
  },
  {
    id: 4,
    name: "Boys",
  },
  {
    id: 4,
    name: "Girls",
  },
];
const ProductList = () => {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [productInfo, setProductInfo] = useState(productList);

  const handleOpen = (data) => {
    setOpen(data);
  };

  const handleIndex = (index, open) => {
    setCurrentIndex(index);
    setOpen(open);
  };

  const [activeIndex, setActiveIndex] = useState("All");

  const handleActiveIndex = useCallback(
    (name) => {
      setActiveIndex(name);
    },
    [activeIndex]
  );

  useEffect(() => {
    if (activeIndex === "All") {
      setProductInfo(productList);
    } else {
      const data = productList.filter((value) => value.type === activeIndex);
      setProductInfo(data);
    }
  }, [activeIndex]);
  return (
    <div>
      <div className="relative pt-48 pb-12 bg-black xl:pt-60 sm:pb-16 lg:pb-32 xl:pb-48 2xl:pb-56">
        <div className="absolute inset-0 overflow-hidden">
          <img
            className="object-cover w-full h-full animate-scale"
            src="product_banner.png"
            alt=""
          />
        </div>
        <div className="relative">
          <div className="px-6 mx-auto sm:px-8 lg:px-12 max-w-7xl">
            <div className="w-full lg:w-2/3 xl:w-1/2">
              <h1 className="font-sans text-base font-normal tracking-tight text-white text-opacity-70">
                PRODUCTS
              </h1>
              <p className="mt-6 tracking-tighter text-[#80C42B]">
                <TypeAnimation
                  sequence={[
                    "OUR",
                    1000,
                    "SPECIALIZED",
                    2000,
                    "DENIM APPARELS",
                    () => {},
                  ]}
                  wrapper="span"
                  cursor={true}
                  repeat={Infinity}
                  style={{ fontSize: "4rem", display: "inline-block" }}
                />
                <br />
                <span className="font-serif italic font-normal text-8xl"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-8">
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 ">
          {galleryFilter?.map((item, index) => (
            <div
              className={`transition-all lg:h-[400px] md:h-[300px] sm:h-[200px] h-[180px] group relative duration-200 border border-neutral cursor-pointer rounded flex items-center justify-center ${
                activeIndex === item.name ? "bg-[#80C42B]" : "bg-[#313033]"
              }`}
              onClick={() => handleActiveIndex(item.name)}
              key={index}
            >
              <div className="">
                <div className="w-16 h-16 border absolute group-hover:bg-white top-8 left-8 rounded-full flex items-center justify-center">
                  <p className="text-white group-hover:text-black">
                    0{index + 1}
                  </p>
                </div>
                <div className="px-4 py-5">
                  <h3 className="mt-2 font-sans text-3xl absolute bottom-6 left-6 font-normal text-white">
                    {item?.name}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
        <section className="py-12 bg-white sm:py-16 lg:py-20">
          <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-[1460px]">
            <div className="grid grid-cols-2 gap-4 mt-8 sm:mt-12 sm:gap-6 lg:grid-cols-4">
              {productInfo.map((item, index) => (
                <div
                  onClick={() => handleIndex(index, true)}
                  className="relative flex flex-col p-9 overflow-hidden bg-[#F6F6F6] rounded-xl group"
                  key={index}
                >
                  <div className="flex-shrink-0 overflow-hidden aspect-w-1 aspect-h-1">
                    <img
                      width={40}
                      height={40}
                      className="ml-auto"
                      src="/icon4.png"
                    />
                    <img
                      className="object-cover w-full h-full transition-all duration-300 group-hover:scale-125"
                      src={item.imgUrl}
                      alt=""
                    />
                  </div>
                  <div className="flex flex-col flex-1 px-4 py-5 sm:px-5 sm:py-6">
                    <h3 className="text-sm sm:text-base font-bold text-gray-900 flex-1 mt-2.5">
                      <a title="">
                        {productInfo[currentIndex].name}
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      {open && (
        <Model setOpen={handleOpen}>
          <section className="py-10 bg-gray-900 sm:py-16 lg:py-20">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
              <div className="relative bg-white sm:p-10 xl:p-14">
                <div className="grid grid-cols-1 lg:grid-cols-12 gap-y-8 lg:gap-x-10 xl:gap-x-20">
                  <div className="relative lg:col-span-5">
                    <img
                      className="object-cover w-full h-full sm:rounded-md"
                      src={productInfo[currentIndex].imgUrl}
                      alt=""
                    />
                  </div>
                  <div className="px-4 pb-8 lg:col-span-7 xl:pr-16">
                    <nav className="flex">
                      <ol role="list" className="flex items-center space-x-1">
                        <li>
                          <div className="-m-1">
                            <a
                              href="#"
                              className="p-1 text-sm font-medium text-gray-600 rounded-md focus:outline-none focus:ring-2 focus:text-gray-900 focus:ring-gray-900 hover:text-gray-700"
                            >
                              Home
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="flex items-center">
                            <span className="flex-shrink-0 text-gray-300">
                              -
                            </span>
                            <div className="-m-1">
                              <a
                                href="#"
                                className="p-1 ml-1 text-sm font-medium text-gray-600 rounded-md focus:outline-none focus:ring-2 focus:text-gray-900 focus:ring-gray-900 hover:text-gray-700"
                              >
                                Products
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="flex items-center">
                            <span className="flex-shrink-0 text-gray-300">
                              -
                            </span>
                            <div className="-m-1">
                              <a
                                href="#"
                                className="p-1 ml-1 text-sm font-medium text-gray-400 rounded-md focus:outline-none focus:ring-2 focus:text-gray-900 focus:ring-gray-900 hover:text-gray-700"
                                aria-current="page"
                              >
                                {productInfo[currentIndex].name}
                              </a>
                            </div>
                          </div>
                        </li>
                      </ol>
                    </nav>
                    <h1 className="mt-8 text-3xl font-bold text-gray-900 sm:text-4xl">
                      {productInfo[currentIndex].name}
                    </h1>
                    <h2 className="mt-5 text-base font-bold text-gray-900">
                      Materials:
                    </h2>
                    <ul className="mt-4 space-y-3 text-base font-medium text-gray-600 list-disc list-inside">
                      <li>{productInfo[currentIndex].material}</li>
                    </ul>
                    <h2 className="mt-5 text-base font-bold text-gray-900">
                      Weight:
                    </h2>
                    <ul className="mt-4 space-y-3 text-base font-medium text-gray-600 list-disc list-inside">
                      {productInfo[currentIndex].weight}
                    </ul>
                    <h2 className="mt-5 text-base font-bold text-gray-900">
                      Size:
                    </h2>
                    <ul className="mt-4 space-y-3 text-base font-medium text-gray-600 list-disc list-inside">
                      {productInfo[currentIndex].size}
                    </ul>
                    <h2 className="mt-5 text-base font-bold text-gray-900">
                      Type:
                    </h2>
                    <ul className="mt-4 space-y-3 text-base font-medium text-gray-600 list-disc list-inside">
                      {productInfo[currentIndex].type}
                    </ul>
                  </div>
                </div>
                <div
                  onClick={() => setOpen(false)}
                  className="absolute top-3 right-3 lg:top-4 lg:right-4"
                >
                  <button
                    type="button"
                    className="p-1 -m-1 text-white transition-all duration-200 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 lg:text-gray-400 hover:text-gray-900 hover:bg-gray-100"
                  >
                    <svg
                      className="w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </Model>
      )}
    </div>
  );
};

export default ProductList;
