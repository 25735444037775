import React from "react";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import ServiceList from "./ServiceList";

const Service = () => {
  return (
    <div>
      <Header />
      <ServiceList />
      <Footer />
    </div>
  );
};

export default Service;
