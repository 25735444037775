import React from "react";
import { TypeAnimation } from "react-type-animation";
import GallaryImages from "./GallaryImages";
import "../../hero/style.css";

const GallaryList = () => {
  return (
    <div>
      <div>
        <div>
          <div className="relative pt-48 pb-12 bg-black xl:pt-60 sm:pb-16 lg:pb-32 xl:pb-48 2xl:pb-56">
            <div className="absolute inset-0 overflow-hidden">
              <img
                className="object-cover w-full h-full animate-scale"
                src="https://ecosourcing.org/dev/admin/uploads/page/gallery/1646305658D5S7b.jpg"
                alt=""
              />
            </div>

            <div className="relative">
              <div className="px-6 mx-auto sm:px-8 lg:px-12 max-w-7xl">
                <div className="w-full lg:w-2/3 xl:w-1/2">
                  <h1 className="font-sans text-base font-normal tracking-tight text-white text-opacity-70">
                    GALLERY
                  </h1>
                  <p className="mt-6 tracking-tighter text-[#80C42B]">
                    {/* <span className="font-sans font-normal text-7xl"> */}
                    <TypeAnimation
                      sequence={[
                        "HAVE A SNEAK", // Types 'One'
                        1000, // Waits 1s
                        "PEAK OF", // Deletes 'One' and types 'Two'
                        2000, // Waits 2s
                        "OUR WORK", // Types 'Three' without deleting 'Two'
                        () => {},
                      ]}
                      wrapper="span"
                      cursor={true}
                      repeat={Infinity}
                      style={{ fontSize: "3.5rem", display: "inline-block" }}
                    />
                    {/* </span> */}
                    <br />
                    <span className="font-serif italic font-normal text-8xl"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <GallaryImages />
      </div>
    </div>
  );
};

export default GallaryList;
