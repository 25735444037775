import React from "react";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import ProductList from "./ProductList";

const Products = () => {
  return (
    <div>
      <Header />
      <ProductList />
      <Footer />
    </div>
  );
};

export default Products;
