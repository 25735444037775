import React from "react";

const BlogFeature = () => {
  return (
    <div>
      <section className="py-12  bg-white sm:py-16 lg:py-28">
        <div className="">
          <div className="relative overflow-hidden pl-[10%]">
            <div className="absolute inset-0">
              <img
                className="object-cover w-full h-full"
                src="banner2.png"
                alt=""
              />
            </div>
            <div className="absolute inset-0 backdrop-blur-[1px] bg-gradient-to-r from-gray-900 via-gray-900/50 lg:via-gray-900/20 to-transparent"></div>

            <div className="relative p-8  sm:py-12 sm:px-16 sm:max-w-3xl">
              <p className="mt-12 text-2xl font-bold text-white sm:text-3xl">
                <h2 className="text-3xl uppercase font-bold leading-tight text-white sm:text-4xl lg:text-5xl lg:leading-tight">
                  JOIN HOLA GARMENTS in Fashioning a Sustainable Future
                </h2>
              </p>

              <p className="mt-4 text-base font-normal leading-7 text-white">
                As we embark on this mission, we invite our customers to join us
                in making a positive difference. By choosing HOLA GARMENTS,
                you're not only investing in fashionable attire but also
                supporting a brand that prioritizes environmental stewardship.
                Together, we can pave the way for a more sustainable future, one
                garment at a time
              </p>
              <div className="mt-12">
                <a
                  href="#"
                  title=""
                  className="inline-flex items-center justify-center px-6 py-3 text-base font-bold !text-white transition-all duration-200 bg-[#80C42B] border border-transparent rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                  role="button"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogFeature;
