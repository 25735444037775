import React from "react";

const Success = () => {
  return (
    <div>
      <section className="py-10 bg-white sm:py-16 lg:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl lg:leading-tight">
            WHY CHOOSE US
            </h2>
            <p className="max-w-2xl mx-auto mt-4 text-xl text-gray-600">
            Hola Garments is continually embracing the global sustainable product revolution.
            </p>
          </div>

          <div className="grid lg:grid-cols-4 items-end grid-cols-2 gap-6 mt-8 sm:grid-cols-3 md:mt-16 lg:gap-x-6">
            <div>
              <img className="w-full" src="task1.jpg" alt="" />
            </div>

            <div>
              <img className="w-full" src="task2.jpg" alt="" />
            </div>

            <div>
              <img className="w-full" src="task3.jpg" alt="" />
            </div>
            <div>
              <img className="w-full" src="task4.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Success;
