import React from "react";

const Feature = () => {
  return (
    <section
      style={{ backgroundImage: "url(bg.png)" }}
      className="py-12  relative sm:py-16 lg:20"
    >
      {/* <img src="bg.png" className="absolute left-0 right-0 z-0" /> */}
      <div className="px-6 mx-auto sm:px-8 lg:px-12 max-w-7xl">
        <div className="grid lg:grid-cols-1 gap-y-12 xl:grid-cols-5 xl:gap-x-16">
          <div className="max-w-lg   xl:col-span-2">
            <h2 className="text-3xl  font-bold  leading-tight text-black sm:text-4xl lg:text-5xl lg:leading-tight">
              Our Services
            </h2>

            <p className="mt-6 font-sans   text-lg text-opacity-50 text-black">
              Elevating experiences to new heights, our superior services are
              crafted to delight and inspire.
            </p>
            <div className="mt-8  ">
              <a
                href="#"
                title=""
                className="
                            inline-flex
                            items-center
                            justify-center
                            px-5
                            py-2
                            font-sans
                            text-base
                            font-semibold
                            transition-all
                            duration-200
                            bg-[#80C42B]
                            border-2
                            rounded-lg
                            sm:leading-8
                            text-white
                            border-primary
                            hover:bg-white
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary
                            hover:text-black
                            sm:text-lg
                            focus:ring-offset-secondary
                        "
                role="button"
              >
                Contact us
                <svg
                  className="w-6 h-6 ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  />
                </svg>
              </a>
            </div>
          </div>

          <div className="xl:col-span-3 ">
            <div className="grid grid-cols-2 gap-5 text-center md:grid-cols-3">
              <div className="transition-all min-h-[225px] group relative duration-200 border rounded-lg bg-white border-neutral hover:bg-dark-gray">
                <div className="px-4 py-5 sm:px-6 sm:py-12">
                  {/* <svg
                    className="w-12 h-12 mx-auto text-black"
                    viewBox="0 0 45 45"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M30.9375 22.5C33.525 22.5 35.6063 20.4 35.6063 17.8125C35.6063 15.225 33.525 13.125 30.9375 13.125C28.35 13.125 26.25 15.225 26.25 17.8125C26.25 20.4 28.35 22.5 30.9375 22.5ZM16.875 20.625C19.9875 20.625 22.4813 18.1125 22.4813 15C22.4813 11.8875 19.9875 9.375 16.875 9.375C13.7625 9.375 11.25 11.8875 11.25 15C11.25 18.1125 13.7625 20.625 16.875 20.625ZM30.9375 26.25C27.5063 26.25 20.625 27.975 20.625 31.4062V33.75C20.625 34.7812 21.4688 35.625 22.5 35.625H39.375C40.4062 35.625 41.25 34.7812 41.25 33.75V31.4062C41.25 27.975 34.3687 26.25 30.9375 26.25ZM16.875 24.375C12.5063 24.375 3.75 26.5688 3.75 30.9375V33.75C3.75 34.7812 4.59375 35.625 5.625 35.625H16.875V31.4062C16.875 29.8125 17.4938 27.0187 21.3188 24.9C19.6875 24.5625 18.1125 24.375 16.875 24.375Z"
                    />
                  </svg> */}
                  <h3 className="mt-2 font-sans text-xl font-normal md:text-2xl text-black">
                    Sourcing
                  </h3>
                  <div class="opacity-0 w-full flex items-center justify-center  rounded-lg h-[0px] transition-all duration-300 group-hover:opacity-100 group-hover:h-[225px] duration-300 absolute inset-x-0 bottom-0 flex justify-center items-end text-xl bg-[#80c42b] text-black font-semibold">
                    <h3 className="mt-2 font-sans text-xl font-normal md:text-2xl text-white">
                      Sourcing
                    </h3>
                  </div>
                </div>
              </div>

              <div className="transition-all min-h-[225px] duration-200 group relative border rounded-lg bg-white border-neutral hover:bg-dark-gray">
                <div className="px-4 py-5 sm:px-6 sm:py-12 ">
                  <h3 className="mt-2 font-sans text-xl font-normal md:text-2xl text-black">
                    Embracing Sustainability
                  </h3>
                  <div class="opacity-0 w-full flex items-center justify-center  rounded-lg h-[0px] transition-all duration-300 group-hover:opacity-100 group-hover:h-[225px] duration-300 absolute inset-x-0 bottom-0 flex justify-center items-end text-xl bg-[#80c42b] text-black font-semibold">
                    <h3 className="mt-2 font-sans text-xl font-normal md:text-2xl text-white">
                      Embracing Sustainability
                    </h3>
                  </div>
                </div>
              </div>

              <div className="transition-all min-h-[225px] group relative duration-200 border rounded-lg bg-white border-neutral hover:bg-dark-gray">
                <div className="px-4 py-5 sm:px-6 sm:py-12 ">
                  <h3 className="mt-2 font-sans text-xl font-normal md:text-2xl text-black">
                    Creative Studio
                  </h3>
                  <div class="opacity-0 w-full flex items-center justify-center  rounded-lg h-[0px] transition-all duration-300 group-hover:opacity-100 group-hover:h-[225px] duration-300 absolute inset-x-0 bottom-0 flex justify-center items-end text-xl bg-[#80c42b] text-black font-semibold">
                    <h3 className="mt-2 font-sans text-xl font-normal md:text-2xl text-white">
                      Creative Studio
                    </h3>
                  </div>
                </div>
              </div>

              <div className="transition-all min-h-[225px] group relative duration-200 border rounded-lg bg-white border-neutral hover:bg-dark-gray">
                <div className="px-4 py-5 sm:px-6 sm:py-12 ">
                  <h3 className="mt-2 font-sans text-xl font-normal md:text-2xl text-black">
                    Quality Assurance
                  </h3>
                  <div class="opacity-0 w-full flex items-center justify-center  rounded-lg h-[0px] transition-all duration-300 group-hover:opacity-100 group-hover:h-[225px] duration-300 absolute inset-x-0 bottom-0 flex justify-center items-end text-xl bg-[#80c42b] text-black font-semibold">
                    <h3 className="mt-2 font-sans text-xl font-normal md:text-2xl text-white">
                      Quality Assurance
                    </h3>
                  </div>
                </div>
              </div>

              <div className="transition-all min-h-[225px] group relative duration-200 border rounded-lg bg-white border-neutral hover:bg-dark-gray">
                <div className="px-4 py-5 sm:px-6 sm:py-12 ">
                  <h3 className="mt-2 font-sans text-xl font-normal md:text-2xl text-black">
                    Procurement Assistance Unit
                  </h3>
                  <div class="opacity-0 w-full flex items-center justify-center  rounded-lg h-[0px] transition-all duration-300 group-hover:opacity-100 group-hover:h-[225px] duration-300 absolute inset-x-0 bottom-0 flex justify-center items-end text-xl bg-[#80c42b] text-black font-semibold">
                    <h3 className="mt-2 font-sans text-xl font-normal md:text-2xl text-white">
                      Procurement Assistance Unit
                    </h3>
                  </div>
                </div>
              </div>

              <div className="transition-all min-h-[225px] group relative duration-200 border rounded-lg bg-white border-neutral hover:bg-dark-gray">
                <div className="px-4 py-5 sm:px-6 sm:py-12 ">
                  <h3 className="mt-2 font-sans text-xl font-normal md:text-2xl text-black">
                    Supply Management
                  </h3>
                  <div class="opacity-0 w-full flex items-center justify-center  rounded-lg h-[0px] transition-all duration-300 group-hover:opacity-100 group-hover:h-[225px] duration-300 absolute inset-x-0 bottom-0 flex justify-center items-end text-xl bg-[#80c42b] text-black font-semibold">
                    <h3 className="mt-2 font-sans text-xl font-normal md:text-2xl text-white">
                      Supply Management
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
