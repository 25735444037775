import React from "react";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import GallaryList from "./GallaryList";

const Gallary = () => {
  return (
    <div>
      <Header />
      <GallaryList />
      <Footer />
    </div>
  );
};

export default Gallary;
